<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        {{lookups}}
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0" v-if="tab==='lookup'">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 class="text-center" v-if="payload.created_on">Update Lookup</h4>
                <h4 v-else class="text-center">Add New Lookup</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>ID</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        class="form-control form-control-sm"
                        :disabled="payload.created_on"
                        placeholder="Label"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Title</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Title"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Lookup Type</b></label>
                      <select name="" id="" v-model="payload.lookup_type" class="form-control form-control-sm">
                        <option value="CAT">CAT</option>
                      </select>
                      <span class="error" v-if="error && error.lookup_type">{{
                        error.lookup_type
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="mode==='edit'"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Lookup
                    </button>
                    <button
                      v-else
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Lookup
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="parent-body pad-0" v-if="tab==='lookupCode'">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 class="text-center" v-if="!payload.id">Add New Lookup Code</h4>
                <h4 class="text-center" v-if="payload.id">Update Lookup Code</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>LOOKUP</b></label>
                      <select name="" id="" v-model="payload.lookup" class="form-control form-control-sm">
                        <template v-for="itm in lookupList" :key="itm.id">
                          <option :value="itm.id">{{itm.id}}</option>
                        </template>
                      </select>
                      <span class="error" v-if="error && error.lookup">{{
                        error.lookup
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>ID</b></label>
                      <input
                        type="text"
                        v-model="payload.id"
                        class="form-control form-control-sm"
                        placeholder="ID"
                      />
                      <span class="error" v-if="error && error.id">{{
                        error.id
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Description"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="col-3">
                    <div class="form-check">
                      <input type="checkbox" v-model="payload.is_default" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">Make as default</label>
                    </div>
                  </div> -->
                  <div class="col-12 text-right">
                    <button
                      v-if="mode===null"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Lookup Code
                    </button>
                    <button
                      v-else
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Lookup Code
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'lookup' }" @click="tab='lookup'">Lookups</li>
              <li :class="{ active: tab === 'lookupCode' }" @click="tab='lookupCode';lookup=null">LookupCodes</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    placeholder="Search Word"
                    v-model="search_term"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-3" v-if="tab==='lookupCode'">
                <div class="form-group">
                  <label for=""><b>LOOKUP</b></label>
                  <select name="" id="" v-model="lookup" class="form-control form-control-sm">
                    <option :value="null">Select Lookup</option>
                    <template v-for="itm in lookupList" :key="itm.id">
                      <option :value="itm.id">{{itm.id}}</option>
                    </template>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="lookup=null;clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                      mode=null
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Lookup
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th
                    v-if="tab==='lookup'"
                    :class="{
                      active_sort: sort_by == 'id' || sort_by == '-id',
                    }"
                    @click="sortBy('id')"
                  >
                    ID
                    <i
                      :class="{
                        'fa-sort': sort_by != 'id',
                        'fa-sort-up': sort_by == 'id',
                        'fa-sort-down': sort_by == '-id',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th v-if="tab==='lookupCode'"
                  :class="{
                      active_sort: sort_by == 'lookup' || sort_by == '-lookup',
                    }"
                    @click="sortBy('lookup')"
                  
                  >LOOKUP
                  <i
                      :class="{
                        'fa-sort': sort_by != 'lookup',
                        'fa-sort-up': sort_by == 'lookup',
                        'fa-sort-down': sort_by == '-lookup',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th v-if="tab==='lookupCode'" :class="{
                      active_sort: sort_by == 'id' || sort_by == '-id',
                    }"
                    @click="sortBy('id')">
                  CODE
                  <i
                      :class="{
                        'fa-sort': sort_by != 'id',
                        'fa-sort-up': sort_by == 'id',
                        'fa-sort-down': sort_by == '-id',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    /></th>
                  <th>TITLE</th>
                  <th v-if="tab==='lookup'">TYPE</th>
                  <th>ORDER</th>
                  <th :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')">STATUS <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    /></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td v-if="tab==='lookup'">{{ item.id }}</td>
                      <td v-if="tab==='lookupCode'">{{ item.lookup }}</td>
                      <td v-if="tab==='lookupCode'"><sup class="text-danger fa fa-circle" style="font-size: 0.5em;" v-if="item.is_default"></sup> {{ item.id }}</td>
                      <td>{{ item.title }}</td>
                      <td v-if="tab==='lookup'">{{ item.lookup_type }}</td>
                      <td>{{ item.order }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>ACTIVE</b></span
                        >
                        <span class="text-warning" v-else><b>INACTIVE</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item);mode='edit'"
                        /></td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  components: {
  },
  data() {
    return {
      tab: 'lookup',
      serviceModule: "lookup",
      lookupList: [],
      lookup: null,
      mode: null,
      defaultPayload: this.tab==='lookup'?{
        id: null,
        title: null,
        lookup_type: "CAT",
        order: 1,
        status: true
      }:{
        id: null,
        lookup: null,
        title: null,
        order: 1,
        status: true
      },
    };
  },
  mixins: [DataMixin],
  watch: {
    tab: function(newVal, oldVal) {
      this.serviceModule = newVal;
      this.getdataList();
      this.getLookupList();
    }
  },
  created() {
    this.getLookupList();
  },
  methods: {
    extraParams() {
      if(this.tab === "lookupCode" && this.lookup !== null) {
        return {
          lookup: this.lookup
        }
      }
      return {};
    },
    getLookupList() {
      const that = this;
      that.$cs.lookup
        .list({
            pageSize: 10000,
        })
        .then(
          data => {
            that.lookupList = data.data.results;
          },
          () => {
            console.log("error");
          }
        );
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if(this.tab === 'lookup') {
        if (
          that.payload.title === null ||
          that.payload.title === ""
        ) {
          this.error.title = "Description is required";
          proceed = false;
        }
        if (
          that.payload.id === null ||
          that.payload.id === ""
        ) {
          this.error.id = "ID is required";
          proceed = false;
        }
      }
      if(this.tab === 'lookupCode') {
        if (
          that.payload.title === null ||
          that.payload.title === ""
        ) {
          this.error.title = "Description is required";
          proceed = false;
        }
        if (
          that.payload.code === null ||
          that.payload.code === ""
        ) {
          this.error.code = "code is required";
          proceed = false;
        }
        if (
          that.payload.lookup === null ||
          that.payload.lookup === ""
        ) {
          this.error.lookup = "lookup is required";
          proceed = false;
        }
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>